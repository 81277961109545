.layout {
  margin: 0 auto;

  background-color: #ffffff;

  & .container {
    margin-top: 26px;
    padding-top: 166px;

    @media screen and (max-width: 900px) {
      margin-top: 0;
    }
  }
}
