.header {
  height: 192px;
  width: 100%;

  background-color: #fff;

  position: fixed;
  top: 0;
  left: 0;

  transition: all 0.3s ease;

  z-index: 100;

  &__nav {
    display: flex;
    justify-content: center;
    height: 144px;

    & .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      background-color: transparent;
      color: #000;
      text-decoration: none;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &--left {
      display: flex;
      margin-right: 60px;

      & .nav-link {
        width: 130px;
        margin: 49px 0 55px 0;

        font-size: 15px;
        justify-content: end;
      }
    }

    &--home {
      & .nav-link {
        height: 80px;
        margin: 19px 0 40px 0;

        font-size: 69px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &--right {
      display: flex;
      margin-left: 60px;


      & .nav-link {
        width: 130px;
        margin: 49px 0 55px 0;
        padding: 0;

        font-size: 15px;
        justify-content: start;
      }
    }
  }

  &__section {
    height: 48px;
    background-color: rgb(36, 35, 35);
  }
}

.header-show {
  transform: translateY(0);
}

.header-hide {
  transform: translateY(-192px);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;

  opacity: 0;
  visibility: hidden;
  transform: translateX(378px);

  transition: all 0.3s ease;

  z-index: 101;

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;

    background-color: transparent;
  }

  &__content {
    width: 378px;
    height: 100vh;

    color: #fff;
    background-color: rgba(20, 20, 22, 0.822);

    position: fixed;
    top: 0;
    right: 0;

    z-index: 102;

    @media screen and (max-width: 600px) {
      width: 100vw;
    }

    &--btn {
      display: flex;
      justify-content: flex-end;
      padding: 30px 30px;

      & i {
        font-size: 30px;
        user-select: none;
        cursor: pointer;
      }
    }

    &--btn-connect {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 80px;

      & .bg-img {
        width: 95px;
        height: auto;

        margin-bottom: 50px;
      }

      & .bg-text {
        padding: 0 50px;
        font-size: 19px;
      }

      & .btn-connect__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 50px;

        cursor: pointer;

        & img {
          height: 95px;
          margin-right: 20px;

          @media screen and (max-width: 600px) {
            margin-right: 0;
          }
        }

        & span {
          font-size: 30px;
        }
      }
    }
  }
}

.modal-show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.header__mobile {
  height: 110px;
  width: 100%;
  display: none;

  background-color: #fff;

  position: fixed;
  top: 0;
  left: 0;

  justify-content: space-between;
  align-items: center;

  transition: all 0.3s ease;

  z-index: 100;

  &--dropdown {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 0;

    flex-direction: column;

    background-color: white;

    transition: height 0.3s, bottom 0.3s;

    overflow: hidden;
    z-index: 1;

    & .nav-link {
      width: 100%;
      height: 50px;
      padding: 0;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      background-color: transparent;
      color: #000;
      text-decoration: none;

      cursor: pointer;

      font-size: 16px;
      font-weight: 400;
    }

    & .nav-link::after {
      content: "";
      position: absolute;
      left: 38%;
      right: 38%;
      bottom: 0;
      border-bottom: 2px solid #000;
      opacity: 0.25;
    }
  }

  &--active {
    height: 220px;
    bottom: -220px;
  }

  .header__nav--home {
    z-index: 2;

    .nav-link {
      margin: 29px 0 29px 39px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: none;
      background-color: transparent;
      color: #000;
      font-size: 41px;
      text-decoration: none;
    }
  }

  .icon-menu {
    margin-right: 39px;

    background-color: white;
    border: none;

    cursor: pointer;

    z-index: 2;
  }
}

.header__mobile::after {
  content: "";
  display: none;
  height: 56px;
  width: 100%;

  position: absolute;
  bottom: -56px;

  background-color: rgb(36, 35, 35);
}

.headerContainer {
  position: relative;
}

@media screen and (max-width: 900px) {
  .header {
    display: none;
  }

  .header__mobile {
    display: flex;
  }

  .header__mobile::after {
    display: inline;
  }
}
