.partner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    &__img {
        height: 100%;
        width: auto;
        padding: 0 40px;

        @media screen and (max-width: 500px) {
          padding: 0;
        }
    }

    @media screen and (max-width: 900px) {
      padding: 10px;
    }
}
