.banner {
  height: 338px;
  width: 100%;

  position: relative;

  &__img {
    height: 338px;
    width: 100%;

    object-fit: cover;

    @media screen and (max-width: 900px) {
      height: 466px;
    }
  }

  &__content {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 38%;
    left: 50%;

    text-align: center;

    transform: translateX(-50%) translateY(-50%);

    animation-name: load;
    animation-duration: 2s;

    &--title {
      margin-bottom: 10px;
      font-size: 64px;

      @media screen and (max-width: 900px) {
        width: 312px;
      }
    }

    &--sub-title {
      font-size: 32px;
      line-height: 50px;

      @media screen and (max-width: 900px) {
        width: 312px;
      }
    }

    @media screen and (max-width: 900px) {
      top: 45%;
    }
  }

  @media screen and (max-width: 900px) {
    height: 466px;
  }
}

@keyframes load {
    from {
        opacity: 0;
        transform: translateX(-70%) translateY(-50%);
    }
    to {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
    }
}
