.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1170px;
  height: 144px;
  margin:  3px 137px;
  padding: 0 90px 0 48px;

  position: relative;

  box-shadow: 0px 4.48752px 4.48752px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 600px) {
    margin: auto;
    padding: 0 35px 0 15px;
    width: 260px;
    height: 94px;
  }
}

.InfoContainer {
  display: flex;
  align-items: center;

  gap: 18px;
}

.Image {
  width: 84px;
  height: 84px;

  border-radius: 50%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    width: 52px;
    height: 52px;
  }
}

.Name {
  font-style: normal;
  font-weight: bold;
  font-size: 26.3465px;
  line-height: 33px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 20px;
  }
}

.Description, .Url {
  font-style: normal;
  font-weight: 400;
  font-size: 18.1132px;
  line-height: 23px;

  @media screen and (max-width: 600px) {
    font-size: 8px;
    line-height: 8px;
  }
}

.Close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.Close:hover,
.Close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 50px;

  @media screen and (max-width: 600px) {
    gap: 15px;
  }
}

.Accept {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border: none;
  border-radius: 50%;
  background-image: url('../../assets/icons/tick.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  cursor: pointer;
}

.Decline {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border: none;
  border-radius: 50%;
  background-image: url('../../assets/icons/decline.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  cursor: pointer;
}

.ButtonText {
  position: absolute;
  bottom: -18px;
  width: 100%;
  text-align: center;
  display: block;
  font-size: 11px;
}

.Status {
  border: 0.736979px solid #1BAC4B;
  padding: 3px 9px;
  border-radius: 3.3164px;
  font-size: 9.70969px;
  line-height: 12px;
  color: #1BAC4B;
}
