.album {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;

    & .card {
        width: 222px;
        margin: 6px;

        box-shadow: 0px 1px 3px 0px rgba(2, 2, 2, 0.33);
        background-color: white;

        &--content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 67px;

            @media screen and (max-width: 750px) {
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0%);
            }
        }

        &--img {
          @media screen and (max-width: 750px) {
            position: absolute;
            height: 132px;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0%);
          }
        }

        &:hover {
          scale: 110%;
          z-index: 1;
        }

        @media screen and (max-width: 750px) {
          width: 134px;
          height: 192px;

          position: relative;

          border: 4px solid white;
          padding: 2px;

          overflow: hidden;
        }
    }

    &__items {
      @media screen and (max-width: 750px) {
        display: flex;
        justify-content:  center;
      }
    }

    @media screen and (max-width: 750px) {
      flex-direction: column;
    }
}
