.Container {
  margin-top: 110px;

  @media screen and (max-width: 600px) {
    margin-top: 26px;
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1170px;
  height: 144px;
  margin:  3px 137px;
  padding: 0 90px 0 48px;

  position: relative;

  box-shadow: 0px 4.48752px 4.48752px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;

  @media screen and (max-width: 600px) {
    margin: auto;
    padding: 0 35px 0 15px;
    width: 260px;
    height: 94px;
  }
}

.Line {
  width: 10px;
  height: 32px;

  position: absolute;
  left: 0;

  background-color: rgba(3, 113, 34, 1);
}
