.create-page {
    max-width: 1020px;
    margin: 0 auto;

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 80px 0;

        &--form-control {
            padding: 20px 10px;
            margin-bottom: 20px;

            & .title {
                font-size: 24px;
                font-weight: bold;
            }

            & .sub-title {
                margin-top: 5px;

                font-size: 18px;
            }

            & [type=file] {
                display: none;
            }

            & .input-file {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 137px;
                height: 137px;
                margin-top: 20px;

                border: 2px dashed #000;
                border-radius: 50%;

                cursor: pointer;

                & img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                }

                & i {
                    margin-left: 10px;
                }

                &:hover {
                    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(40, 38, 38, 0.07) 0%, rgba(40, 38, 38, 0.07) 100%), no-repeat padding-box border-box 46% 53%/cover scroll url("https://static.wixstatic.com/media/a3c153_8d1a945d2515486c824c292509617008~mv2.jpg/v1/fill/w_1920,h_1165,al_c,q_90/a3c153_8d1a945d2515486c824c292509617008~mv2.webp?formatterValues=a3c153_8d1a945d2515486c824c292509617008~mv2.jpg,1920,1165");
                }
            }

            & .input-file-loading {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 137px;
                height: 137px;
                margin-top: 20px;

                border: 2px dashed #000;
                border-radius: 50%;

                cursor: pointer;

                & .loading {
                  margin: 0 !important;
                  animation: spinner-loading 2s linear infinite;
                }
            }

            & .input-text {
                width: 287px;
                height: 36px;
                padding: 0 24px;
                margin-top: 20px;

                text-align: center;

                border: 2px dashed #000;
                border-radius: 4px;
            }

            & .input-text-area {
                width: 489px;
                padding: 0 24px;
                margin-top: 20px;

                text-align: center;

                border: 2px dashed #000;
                border-radius: 4px;
            }
        }

        & .btn-submit {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 183px;
            height: 56px;
            margin: 100px 10px 0 10px;

            font-size: 38px;
            font-family: customFont;

            background-color: transparent;
            border-radius: 4px;
            border: 1px solid #000;

            transition: all .3s ease;
            cursor: pointer;

            &:hover {
                color: #fff;
                background-color: #000;
            }
        }
    }
}

.mb-20 {
    margin-bottom: 20px;
}

@keyframes spinner-loading {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
